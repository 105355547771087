import { useState, useEffect, useMemo } from 'react'
import { useResponsive } from '../../../utils/responsiveHelpers';
import { currentNumber } from './counter.const';

export const useCounter = () => {
    const [number, setNumber] = useState(currentNumber);
    const { isXxxl, isLg } = useResponsive();
    useEffect(() => {
        const interval = setInterval(() => {
            setNumber((state) => state + 0.01)
        }, 1000)

        return () => clearInterval(interval)
    }, [])

    const width = useMemo(() => {
        if (isXxxl) {
            return 40;
        }

        if (isLg) {
            return 30;
        }

        return 20;
    }, [isXxxl, isLg])

    const height = useMemo(() => {
        if (isXxxl) {
            return 60;
        }

        if (isLg) {
            return 50;
        }
        return 40;
    }, [isXxxl, isLg])

    return { width, height, number }

}