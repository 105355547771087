import React from 'react';
import { useTranslation } from 'react-i18next'
import { NavHashLink } from 'react-router-hash-link';
import './startup.scss';

const Startup  = () => {
    const { t } = useTranslation();
    return (
        <section id="Startup" className="container hero-wrap" >
            <div className="container">
                <NavHashLink to="/#Offer">
                    <button className='btn-verticle'>{t('startup.industrialArchitecture')}</button>
                </NavHashLink>
                <div className='text-container'>{t("startup.description")}</div>
            </div>
        </section>

    );
}

export default Startup;
