import React from 'react';
import { useTranslation } from 'react-i18next'
import { useScrollTop } from '../../hooks/useScrollTop';
import { Content } from "./pl/Content"
import './recruitmentClause.scss';

const RecruitmentClause = () => {
    const { t } = useTranslation();
    useScrollTop();
    const images = {
        main2: require('../../images/CleanBg.png'),
        icon: require('../../images/recruitmentClause/icon.png'),
    };
    return (
        <section id="Clause" className="hero-wrap" style={{ backgroundImage: `url("${images.main2}")` }}>
            <div className="container">
                <div className='d-flex align-items-center justify-content-center'>
                    <h2>{t('recruitmentClause.header')}</h2>
                </div>
                <div className="row align-items-center justify-content-center">
                    <div style={{ backgroundImage: `url("${images.icon}")`, width: '75px', height: '75px', backgroundSize: 'cover' }}></div>
                </div>
                <Content />
            </div>
        </section>
    );
}

export default RecruitmentClause;
