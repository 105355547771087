import React from 'react';
import { NavHashLink } from 'react-router-hash-link';
import { useIsMobile } from '../../utils/responsiveHelpers';

const NavLink = ({ to, children, onClick }) => {
    const isMobile = useIsMobile()
    return <li className="nav-item" data-toggle={isMobile && 'collapse'} data-target="#ftco-nav">
        <NavHashLink className="nav-link"  smooth activeClassName="active" to={to} onClick={onClick}>{children}</NavHashLink>
    </li>
}

export default NavLink;