import React from 'react';
import FlipNumbers from 'react-flip-numbers';
import { useCounter } from './counter.hooks';

const Counter = () => {

    const { height, width, number } = useCounter();

    return <div className='h-100 w-100 d-flex align-items-center justify-content-center justify-content-lg-center counter'>
        <FlipNumbers
            height={height}
            width={width}
            background="white"
            play
            numberStyle={{ fontFamily: "Lato Regular" }}
            perspective={1000}
            numbers={`${number.toLocaleString(
                'pl-PL',
                { minimumFractionDigits: 2 }
            )}`} />
        <div className='ms-3'>m²</div>
    </div>
}

export default Counter;