import { useCallback, useEffect, useState } from "react";
import ProjectsData from "../../../data/ProjectsData";
import { scroller } from 'react-scroll'
import { useFiltersActions, useFiltersState } from "../../Filters/filters.hooks";

export const useProjects = () => {
  const [projects, setProjects] = useState(ProjectsData().slice());
  const {currentFilters} = useFiltersState();
  const {setAll, setFilters} = useFiltersActions();

  const [currentProject, setCurrentProject] = useState(null)


  useEffect(() => {
    const { filters, all } = currentFilters;
    if (all) {
      setProjects(ProjectsData().slice())
      return;
    }

    let newProjects = [];
    ProjectsData().forEach((project) => {
      filters.forEach(filter => {
        if (project.tags.includes(filter.name) && (filter.status === true) && !newProjects.includes(x => x.id === project.id)) {
          if (!newProjects.find(x => x.id === project.id)) {
            newProjects.push(project);
          }
        }
      })
    })

    setProjects(newProjects)
  }, [currentFilters, setProjects])

  const showProject = useCallback((id) => {
    setCurrentProject(id)
    
  }, [])

  const hideProject = useCallback(() => {
    setCurrentProject(null)
    scroller.scrollTo('Projects', { offset: -100 })
  }, [])

  return { projects, currentProject, showProject, hideProject, setAll, setFilters, currentFilters }
}