import { useCallback, useState } from "react";

export const initialFilters =
{
    filters: [],
    all: true
};

export const useFilters = (initFilters) => {
    const [currentFilters, setState] = useState({ ...initialFilters, filters: initFilters });

    const setAll = useCallback(() => {
        const { filters } = currentFilters;
        filters.forEach(
            filter => {
                filter.status = false;
            }
        );


        setState(prevState => {
            return {
                ...prevState,
                filters: filters,
                all: true,
            }
        })
    }, [currentFilters]);

    const setFilters = useCallback((name) => {
        const { filters } = currentFilters;
        const index = filters.findIndex(x => x.name === name)
        filters[index].status = !filters[index].status;

        setState(prevState => {
            return {
                ...prevState,
                all: false,
                filters: filters
            }
        })

        const allFiltersTrue = filters.every(filter => filter.status === true);
        const allFiltersFalse = filters.every(filter => filter.status === false);

        if (allFiltersTrue || allFiltersFalse) {
            setAll();
        }

    }, [currentFilters, setAll])



    return { setFilters, currentFilters, setAll }
}