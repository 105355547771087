import React from 'react';
import { useTranslation } from 'react-i18next';
import Filters from "../../Filters/Filters";

import { icons } from './projectMap.const';
import Title from '../../Title/Title';
import { useFiltersState } from '../../Filters/filters.hooks';
import { useProjectsMap } from './projectMap.hooks';


const ProjectsMap = () => {
    const { t } = useTranslation();
    
    const {currentFilters} = useFiltersState();
    const {setAllHandler, setFiltersHandler} = useProjectsMap();
 
    return <div className='container mb-5'>
        <Title>{t("projects.header")}</Title>
        <Filters
            onClickAll={setAllHandler}
            all={currentFilters.all}
            onClick={setFiltersHandler}
            filters={currentFilters.filters}
            page="projects"
        />
        <div className='d-flex justify-content-center mt-lg-5'>
            <div><div className='projects-map d-none d-lg-block' style={{ backgroundImage: `url("${icons.polandSmall}` }}></div></div>
            <div><div className='projects-map d-none d-md-block d-lg-none' style={{ backgroundImage: `url("${icons.polandMobileLarge}` }}></div></div>
            <div><div className='projects-map d-md-none' style={{ backgroundImage: `url("${icons.polandMobileSmall}` }}></div></div>
        </div>
    </div>
}
export default ProjectsMap;