import React from 'react';
import { useTranslation } from 'react-i18next'
import { Link } from "react-router-dom";
import Title from '../Title/Title';
import './contact.scss';

const Contact = () => {
  const { t } = useTranslation();
  return (
    <div className="pt-5" id="Contact">
      <div className='container'>
        <Title>{t('contact.header')}</Title>
        <div className="d-flex flex-column flex-md-row align-items-center align-items-md-start justify-content-between contact-header">
          <div className='text-center text-md-start contact-text'>
          <div className="contact-icon text-center">
              <span className="fa fa-file"></span>
            </div>
            <div className='mb-3'>
              <span style={{ fontWeight: 'bold' }}>{t("contact.companyRegistrationDetails")}</span>
            </div>
            <div>
              <span style={{ fontWeight: 'bold' }}>BLUE AXES Sp. z o.o.</span>
            </div>
            <div>Aleja Zjednoczenia 36</div>
            <div>01-830 Warszawa</div>
            <div>KRS: 0000960847</div>
            <div>REGON: 521637375</div>
            <div>NIP 1182239522</div>
          </div>
          <div className="text-center mt-3 mt-md-0">
            <div className="contact-icon">
              <span className="fa fa-map"></span>
            </div>
            <div className='mb-3 contact-text'>
              <span style={{ fontWeight: 'bold' }}>{t("contact.address")}</span>
            </div>
            <div>
              <span style={{ fontWeight: 'bold' }}>BLUE AXES Sp. z o.o.</span>
            </div>
            <div className="contact-text-address">
              <div>Bielany Business Center</div>
              <div>Aleja Zjednoczenia 36</div>
              <div>01-830 Warszawa</div>
              <div>Wejście A, Piętro 3</div>
            </div>
          </div>
          <div className="text-center contact-text mt-3 mt-md-0">
            <div className="contact-icon">
              <span className="fa fa-paper-plane"></span>
            </div>
            <div className='mb-4'>
              <span style={{ fontWeight: 'bold' }}>{t("contact.email")}</span>
            </div>
            <a className='justify-content-center  contact-link' href="mailto: blueaxes@blueaxes.pl"> blueaxes@blueaxes.pl</a>
            <div className='mt-3'>
              <span style={{ fontWeight: 'bold' }}>{t("contact.invoices")}</span>
            </div>
            <a className='justify-content-center contact-link mt-3' href="mailto: faktury@blueaxes.pl"> faktury@blueaxes.pl</a>
          </div>
          <div className="text-center contact-text mt-3 mt-md-0">
            <div className="contact-icon">
              <span className="fa fa-phone"></span>
            </div>
            <div className='mb-4'>
              <span style={{ fontWeight: 'bold' }}>{t("contact.phone")}</span>
            </div>
            <a className='justify-content-center contact-link' href="tel://+48228648118">+48 22 121 16 00 </a>
            <div className='mt-3'><Link className='contact-link' to={`/PrivacyPolicy`}>{t('privacyPolicy.header')}</Link></div>
          </div>
        </div>


        <div className="row justify-content-center pt-5">
          <iframe title="map" style={{ width: '100%', height: 450, border: 0, marginBottom: 50 }}
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2440.9663012728333!2d20.948453516094855!3d52.280313761716535!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471ecbcddb85955d%3A0x44641c7ffdd3873c!2sBielany%20Business%20Center%2C%20Aleja%20Zjednoczenia%2036%2C%2001-830%20Warszawa!5e0!3m2!1spl!2spl!4v1650897387290!5m2!1spl!2spl"
            loading="lazy"></iframe>
        </div>
      </div >
    </div >
  );
}
export default Contact;
