import { useFiltersActions } from "../../Filters/filters.hooks";
import { scroller } from 'react-scroll'

export const useProjectsMap = () => {
    const {setAll, setFilters} = useFiltersActions();

    const scrollToProject = () => {
        scroller.scrollTo('Projects', { offset: -100 })
    }

    const setAllHandler = () => {
        setAll();
        scrollToProject();
    }

    const setFiltersHandler = (filters) => {
        setFilters(filters);
        scrollToProject();
    }

    return {setAllHandler, setFiltersHandler}
}