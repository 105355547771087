import classNames from 'classnames';
import React from 'react';
import './title.scss'

const Title = ({ children, className }) => (
    <div className={classNames('row mb-3 mt-4', className)}>
        <h2 className='title'>{children.toUpperCase()}</h2>
    </div>
)

export default Title;