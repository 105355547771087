import i18next from '../../i18n/config'

export const icons = {
    investmentProcess: require('../../images/offer/investmentProcess.png'),
    objects: require('../../images/offer/objects.png'),
    projects: require('../../images/offer/projects.png'),
    supportServices: require('../../images/offer/supportServices.png'),
};

export const fieldsOfSpecialisationIcons = {
    1: require('../../images/offer/specialties/1.png'),
    2: require('../../images/offer/specialties/2.png'),
    3: require('../../images/offer/specialties/3.png'),
    4: require('../../images/offer/specialties/4.png'),
    5: require('../../images/offer/specialties/5.png'),
    6: require('../../images/offer/specialties/6.png'),
    7: require('../../images/offer/specialties/7.png'),
};

export const fieldsOfDevelopmentIcons = {
    1: require('../../images/offer/growth/1.png'),
    2: require('../../images/offer/growth/2.png'),
    3: require('../../images/offer/growth/3.png'),
    4: require('../../images/offer/growth/4.png'),
    5: require('../../images/offer/growth/5.png'),
};

export const getFields = () => ({
    fieldsOfSpecialisation: [
        {
            icon: fieldsOfSpecialisationIcons[1],
            description: i18next.t("offer.specialties.1")
        }, {
            icon: fieldsOfSpecialisationIcons[2],
            description: i18next.t("offer.specialties.2")
        },
        {
            icon: fieldsOfSpecialisationIcons[3],
            description: i18next.t("offer.specialties.3")
        },
        {
            icon: fieldsOfSpecialisationIcons[4],
            description: i18next.t("offer.specialties.4")
        },
        {
            icon: fieldsOfSpecialisationIcons[5],
            description: i18next.t("offer.specialties.5")
        },
        {
            icon: fieldsOfSpecialisationIcons[6],
            description: i18next.t("offer.specialties.6")
        },
        {
            icon: fieldsOfSpecialisationIcons[7],
            description: i18next.t("offer.specialties.7")
        },
    ],
    fieldsOfDevelopment: [
        {
            icon: fieldsOfDevelopmentIcons[1],
            description: i18next.t("offer.growth.1")
        }, {
            icon: fieldsOfDevelopmentIcons[2],
            description: i18next.t("offer.growth.2")
        },{
            icon: fieldsOfDevelopmentIcons[3],
            description: i18next.t("offer.growth.3")
        },{
            icon: fieldsOfDevelopmentIcons[4],
            description: i18next.t("offer.growth.4")
        },{
            icon: fieldsOfDevelopmentIcons[5],
            description: i18next.t("offer.growth.5")
        },
    ]
});