import React from 'react';
import { useTranslation } from 'react-i18next'
import { icons } from "./offer.const";

const OfferTopSection = () => {
    const { t } = useTranslation();
    return <div className='row justify-content-lg-between' >
        <div className='col-md-12 col-lg-6 item'>
            <div className='row justify-content-md-between g-2 g-md-4'>
                <div className='col-sm-12 col-md-5 col-lg-6 item'>
                    <div><div className='item-image' style={{ backgroundImage: `url("${icons.investmentProcess}` }}></div></div>
                    <p className='item-description'>{t('offer.investmentProcess')}</p>
                </div>
                <div className='col-sm-12 col-md-5 col-lg-4 item'>
                    <div><div className='item-image' style={{ backgroundImage: `url("${icons.objects}` }}></div></div>
                    <p className='item-description'>{t('offer.objects')}</p>
                </div>
            </div>
        </div>
        <div className='col-md-12 col-lg-6 item'>
            <div className='row justify-content-md-between g-2 g-md-4'>
                <div className='col-sm-12 col-md-5 col-lg-5 item offset-lg-2'>
                    <div><div className='item-image' style={{ backgroundImage: `url("${icons.projects}` }}></div></div>
                    <p className='item-description'>{t('offer.projects')}</p>
                </div>
                <div className='col-sm-12 col-md-5 col-lg-4 item'>
                    <div><div className='item-image' style={{ backgroundImage: `url("${icons.supportServices}` }}></div></div>
                    <p className='item-description'>{t('offer.support')}</p>
                </div>
            </div>
        </div>
    </div>
}

export default OfferTopSection;