import ENUM from "../data/Enums";
import { isLang } from "../utils/langHelpers";

class Project {
    constructor(id, title, investor, tags) {
        this.id = id;
        this.title = title;
        this.investor = investor;
        this.image = require(`../images/projects/${id}/main.png`);
        this.tags = [ENUM.Filters.ALL].concat(tags);
        this.detailsPageImages = isLang('en') ? 
        [
            require(`../images/projects/${id}/details/1_en.png`), 
            require(`../images/projects/${id}/details/2_en.png`)
        ] : [
            require(`../images/projects/${id}/details/1_pl.png`), 
            require(`../images/projects/${id}/details/2_pl.png`)
        ];
    }
}

export default Project;