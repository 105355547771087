import React from 'react';


export const Content = () => {
    return (
        <>
            <div className="row pt-5 d-flex">
                <h2 className='col-md-12 ' style={{ fontWeight: 'bold', fontFamily: 'lato', fontSize: '16px' }}>KLAUZULA INFORMACYJNA O PRZETWARZANIU DANYCH OSOBOWYCH w BLUE AXES Sp. z o.o</h2>
            </div>
            <div className="row pt-3 d-flex">
                <div className='col-md-12' style={{ fontFamily: 'Lato Regular', fontSize: '14px' }}>W związku z realizacją wymogów Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych "RODO"), informujemy o zasadach przetwarzania Pani/Pana danych osobowych oraz o przysługujących Pani/Panu prawach z tym związanych.

                    <ol>
                        <li>Administratorem Pani/Pana danych osobowych jest BLUE AXES Sp. z o.o. z siedzibą w Warszawie, Aleja Zjednoczenia 36, 01-830 Warszawa.</li>

                        <li>BLUE AXES Sp. z o.o. przetwarza Pani/Pana dane osobowe na podstawie obowiązujących przepisów prawa, zawartych umów oraz na podstawie udzielonej zgody.</li>

                        <li>Pani/Pana dane osobowe przetwarzane są w celu/celach:

                            <br />a) wypełnienia obowiązków prawnych ciążących na BLUE AXES Sp. z o.o. ;

                            <br />b) realizacji umów zawartych z kontrahentami;

                            <br />c) niezbędność do celów wynikających z prawnie uzasadnionych interesów realizowanych przez administratora;

                            <br />d) w pozostałych przypadkach Pani/Pana dane osobowe przetwarzane są wyłącznie na podstawie wcześniej udzielonej zgody w zakresie i celu określonym w treści zgody.</li>

                        <li>W związku z przetwarzaniem danych w celach o których mowa w pkt 3 odbiorcami Pani/Pana danych osobowych mogą być:

                            <br />a) podmiotom przetwarzającym na zlecenie BLUE AXES Sp. z o.o. ,

                            <br />b) organom lub podmiotom publicznym uprawnionym do uzyskania danych na podstawie obowiązujących przepisów prawa, np. sądom, organom ścigania lub instytucjom państwowym, gdy wystąpią z żądaniem, w oparciu o stosowną podstawę prawną.</li>

                        <li>Pani/Pana dane osobowe będą przechowywane przez okres niezbędny do realizacji celów określonych w pkt 4, a po tym czasie przez okres oraz w zakresie wymaganym przez przepisy powszechnie obowiązującego prawa.</li>

                        <li>Podanie danych osobowych jest dobrowolne, ale niezbędne do zawarcia i realizacji umowy.</li>

                        <li>Pani/Pana dane nie będą przekazane do państw trzecich.</li>

                        <li>Ma Pani/Pan prawo do:

                            <br />a) dostępu do swoich danych osobowych,

                            <br />b) żądania sprostowania swoich danych osobowych, które są nieprawidłowe oraz uzupełnienia niekompletnych danych osobowych,

                            <br />c)żądania usunięcia swoich danych osobowych, w szczególności w przypadku cofnięcia przez Państwa zgody na przetwarzanie, gdy nie ma innej podstawy prawnej przetwarzania,

                            <br />d)żądania ograniczenia przetwarzania swoich danych osobowych,

                            <br />e) wniesienia sprzeciwu wobec przetwarzania swoich danych, ze względu na Państwa szczególną sytuację, w przypadkach, kiedy przetwarzamy Państwa dane na podstawie naszego prawnie usprawiedliwionego interesu czy też na potrzeby marketingu bezpośredniego,

                            <br />f) przenoszenia swoich danych osobowych,

                            <br />g) wniesienia skargi do organu nadzorczego zajmującego się ochroną danych osobowych, tj. Prezesa Urzędu Ochrony Danych Osobowych.</li>

                        <li>Pani/Pana dane nie będą profilowane oraz nie będą poddawane zautomatyzowanemu przetwarzaniu.</li>
                    </ol>
                </div>
            </div>
        </>
    )
}