import { useEffect } from "react";
import ProjectsData from "../../../../data/ProjectsData";
import { scroller } from 'react-scroll'

export const useProject = (id) => {
    const currentProject = ProjectsData().find(x => x.id === id);

    useEffect(() => {
        scroller.scrollTo('Project', { offset: -100 })
    }, [])

    return {currentProject}
}