import { useContext } from "react"
import { FiltersActionsContext, FiltersStateContext } from "./filters.provider"

export const useFiltersActions = () => {
    const { setFilters, setAll } = useContext(FiltersActionsContext)

    return { setFilters, setAll }
}

export const useFiltersState = () => {
    const { currentFilters } = useContext(FiltersStateContext)

    return { currentFilters }
}