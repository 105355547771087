import { useMediaQuery } from "react-responsive";

export const beakpoints = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxxl: 1700,
    xxxxl: 1900,
};


export const useResponsive = () => {
    const isXxxxl = useMediaQuery({ query: `(min-width: ${beakpoints.xxxxl}px)` })
    const isXxxl = useMediaQuery({ query: `(min-width: ${beakpoints.xxxl}px)` })
    const isXl = useMediaQuery({ query: `(min-width: ${beakpoints.xl}px)` })
    const isLg = useMediaQuery({ query: `(min-width: ${beakpoints.lg}px)` })
    const isMd = useMediaQuery({ query: `(min-width: ${beakpoints.md}px)` })
    const isSm = useMediaQuery({ query: `(min-width: ${beakpoints.sm}px)` })
    const isXs = useMediaQuery({ query: `(min-width: ${beakpoints.xs}px)` })

    return {
        isXxxxl, isXxxl, isXl, isLg, isMd, isSm, isXs
    }
}

export const useIsMobile = () => useMediaQuery({ query: `(max-width: ${beakpoints.lg}px)` })