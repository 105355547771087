import React from 'react';
import { useTranslation } from 'react-i18next';
import TeamMember from './TeamMember';
import { team } from './team.const';
import Title from '../Title/Title';
import './team.scss';

const Team = () => {
  const {t} = useTranslation();
  return (
    <section id="Team" className="testimony-section pt-5">
      <div className=" container pt-5">
        <div className="row d-flex justify-content-center">
          <Title>{t("team.header")}</Title>
            <div className="d-flex flex-column flex-lg-row gap-3 align-items-center align-items-lg-start  justify-content-between ">
              {team.filter(x => x.filter === "management").map((member) => {
                return (
                  <div key={member.name} className="d-flex">
                    <TeamMember key={member.name} member={member} />
                  </div>
                )
              })}
            </div>
            <Title>{t("team.header2")}</Title>
            {grouped()}
          </div >
        </div >

      
    </section >
  );

  function grouped(groupSize = 3, array) {
    var rows = team.filter(x => x.filter !== "management").map(function (member, index) {
      return (
        <div key={index} className="d-flex">
          <TeamMember  member={member} />
        </div>
      );
    }).reduce(function (r, element, index) {
      index % groupSize === 0 && r.push([]);
      r[r.length - 1].push(element);
      return r;
    }, []).map(function (rowContent, index) {
      // surround every group with 'row'
      return <div key={index} className="d-flex gap-3 flex-column flex-lg-row align-items-center justify-content-between">{rowContent}</div>;
    });
    return <div>{rows}</div>;
  }
}

export default Team;
