export const icons = {
  managemenet: require('../../images/Kadra.jpg'),
  team: require('../../images/projektanci.jpg'),
};


export const team = [
  {
    'name': 'Marek Cywiński',
    'role': 'ceo',
    'contact': 'mcywinski@blueaxes.pl',
    'photo': icons.managemenet,
    'filter': 'management'
  },
  {
    'name': 'Beata Cywińska',
    'role': 'coo',
    'contact': 'bcywinska@blueaxes.pl',
    'photo': icons.managemenet,
    'filter': 'management'
  },
  {
    'name': 'Tomasz Grochowski',
    'role': 'coo-architect',
    'contact': 'tgrochowski@blueaxes.pl',
    'photo': icons.managemenet,
    'filter': 'management'
  },
  {
    'name': 'Marcin Nowak',
    'role': 'architect',
    'contact': 'mnowak@blueaxes.pl',
    'photo': icons.team,
  },
  {
    'name': 'Piotr Karlicki',
    'role': 'architect',
    'contact': 'pkarlicki@blueaxes.pl',
    'photo': icons.team,
  },
  {
    'name': 'Agnieszka Nowik',
    'role': 'architect',
    'contact': 'anowik@blueaxes.pl',
    'photo': icons.team,
  },
  {
    'name': 'Marta Małachowska',
    'role': 'coordinator',
    'contact': 'mmalachowska@blueaxes.pl',
    'photo': icons.team,
  },
  {
    'name': 'Mateusz Stępkowski',
    'role': 'architect',
    'contact': 'mstepkowski@blueaxes.pl',
    'photo': icons.team,
  },
  {
    'name': 'Andrzej Olejniczak',
    'role': 'architect',
    'contact': 'aolejniczak@blueaxes.pl',
    'photo': icons.team,
  },
  {
    'name': 'Alicja Kozioł',
    'role': 'architect',
    'contact': 'akoziol@blueaxes.pl',
    'photo': icons.team,
  },
    {
    'name': '',
    'role': 'mystery',
    'contact': '',
    'photo': icons.team,
  },
   {
  'name': '',
  'role': 'placeholder',
  'contact': '',
  'photo': '',
  },
]