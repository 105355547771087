import React from 'react';
import ENUM from '../../data/Enums';
import Filter from './Filter/Filter'
import { icons } from './filters.const';

const Filters = ({ onClickAll, all, onClick, filters, page }) =>
(<div className='row justify-content-lg-between' >
    <div className='col-md-12 col-lg-6'>
        <div className='row  g-2 g-md-4 justify-content-lg-between'>
            <div className='col-sm-12 col-md-6 col-lg-6'>
                <Filter page={page} onClick={onClickAll} name={ENUM.Filters.ALL} status={all} icon={icons.all} />
            </div>
            <div className='col-sm-12 col-md-6 col-lg-6'>
                <Filter page={page} onClick={onClick} name={filters[0].name} status={filters[0].status} icon={filters[0].icon} />
            </div>
        </div>
    </div>
    <div className='col-md-12 col-lg-6 '>
        <div className='row justify-content-lg-between'>
            <div className='col-sm-12 col-md-6 col-lg-5 offset-lg-1'>
                <Filter page={page} onClick={onClick} name={filters[1].name} status={filters[1].status} icon={filters[1].icon} />
            </div>
            <div className='col-sm-12 col-md-6 col-lg-5'>
                <Filter page={page} onClick={onClick} name={filters[2].name} status={filters[2].status} icon={filters[2].icon} />
            </div>
        </div>
    </div>
</div>
)


export default Filters;
