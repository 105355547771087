import Project from '../models/Project';
import ENUM from './Enums';

const ProjectsData = () => [
    new Project(1,
        "HILLWOOD PARK BYDGOSZCZ",
        "HILLWOOD POLSKA",
        [ENUM.Filters.RECENT, ENUM.Filters.LOGISTICS],
    ),
    new Project(2,
        "PARK WAN KUTNO",
        "WAN S.A.",
        [ENUM.Filters.RECENT, ENUM.Filters.LOGISTICS],
    ),
    new Project(3,
        "EXETER PARK SZCZECIN",
        "EXETER PROPERTY GROUP",
        [ENUM.Filters.RECENT, ENUM.Filters.LOGISTICS],
    ),
    new Project(4,
        "DL INVEST PARK PSARY",
        "DL PROJECT MANAGEMENT S.A.",
        [ENUM.Filters.RECENT, ENUM.Filters.LOGISTICS],
    ),
    new Project(5,
        "ZALANDO LONGUE AMERYKA",
        "HILLWOOD POLSKA",
        [ENUM.Filters.BTS],
    ),
    new Project(6,
        "HILLWOOD PARK KŁOBUCK",
        "HILLWOOD POLSKA",
        [ENUM.Filters.BTS],
    ),
    new Project(7,
        "MPM LOGISTICS CENTER",
        "PROJEKT NOVA S.A.",
        [ENUM.Filters.PRIVATE],
    ),
    new Project(8,
        "EL-BOX KONOTOPA",
        "EL-BOX Sp. z o.o.",
        [ENUM.Filters.PRIVATE],
    ),
    new Project(9,
        "PARZNIEW LOGISTIC CENTER",
        "HILLWOOD POLSKA",
        [ENUM.Filters.BTS, ENUM.Filters.LOGISTICS],
    ),
    new Project(10,
        "PANATTONI PARK OŻARÓW II",
        "PANNATONI EUROPE",
        [ENUM.Filters.LOGISTICS],
    ), new Project(11,
        "HILLWOOD PARK OLSZTYNEK",
        "HILLWOOD POLSKA",
        [ENUM.Filters.LOGISTICS],  
    ), new Project(12,
        "APM PARK WYPĘDY 1",
        "APM DEVELOPMENT",
        [ENUM.Filters.BTS],
    ), new Project(13,
        "WAIMEA LOGISTIC PARK BYDGOSZCZ",
        "WAIMEA HOLDING S.A.",
        [ENUM.Filters.LOGISTICS],
    ), new Project(14,
        "DĘBICA HUTCHINSON",
        "DL INVEST GROUP S.A.",
        [ENUM.Filters.BTS],
    ), new Project(15,
        "HILLWOOD PARK OLEŚNICA",
        "HILLWOOD POLSKA",
        [ENUM.Filters.LOGISTICS],
    ),  new Project(16,
        "HILLWOOD PARK KUTNO",
        "HILLWOOD POLSKA",
        [ENUM.Filters.LOGISTICS],
    ), new Project(17,
        "BYDGOSZCZ PRZYŁĘKI ZALANDO",
        "HILLWOOD POLSKA",
        [ENUM.Filters.BTS],
    ), new Project(18,
        "PANATTONI PARK OŻARÓW II",
        "TRANSGOURMET SELGROS",
        [ENUM.Filters.BTS],
    ), new Project(19,
        "HILLWOOD PARK ŚWIEBODZIN",
        "HILLWOOD POLSKA",
        [ENUM.Filters.LOGISTICS],
    ), new Project(20,
        "HILLWOOD PARK ZGIERZ A",
        "HILLWOOD POLSKA",
        [ENUM.Filters.LOGISTICS],
    ), new Project(21,
        "HILLWOOD PARK ZGIERZ B",
        "HILLWOOD POLSKA",
        [ENUM.Filters.LOGISTICS],
    ), new Project(22,
        "BIOODPADY OŻARÓW",
        "BIOODPADY.PL Sp z o.o.",
        [ENUM.Filters.PRIVATE],
    ), new Project(23,
        "HILLWOOD PARK GLIWICE WSCHÓD",
        "HILLWOOD POLSKA",
        [ENUM.Filters.LOGISTICS],
    ), new Project(24,
        "HILLWOOD PARK GLIWICE ZACHÓD",
        "HILLWOOD POLSKA",
        [ENUM.Filters.LOGISTICS],
    ), new Project(25,
        "SILESIA LOGISTICS CENTER",
        "MENARD DOSWELL & CO.",
        [ENUM.Filters.BTS],
    ), new Project(26,
        "WHITE STAR LOGISTICS PARK KONIK",
        "WHITE STAR LOGISTICS",
        [ENUM.Filters.LOGISTICS],
    ), new Project(27,
        "ART EXPO KAJETANY",
        "ART EXPO Sp.J.",
        [ENUM.Filters.PRIVATE],
    ), new Project(28,
        "BIOELEKTRA MSZCZONÓW",
        "BIOELEKTRA DEVELOPMENT SP. Z.O.O.",
        [ENUM.Filters.PRIVATE],
    ), new Project(29,
        "PRODREX BIERUŃ",
        "PRODREX-POLAND SP. Z O.O.",
        [ENUM.Filters.PRIVATE],
    ), new Project(30,
        "AMAZON DISTRIBUTION CENTER",
        "HILLWOOD POLSKA",
        [ENUM.Filters.BTS],
    ), new Project(31,
        "HILLWOOD PARK JANOWICE",
        "HILLWOOD POLSKA",
        [ENUM.Filters.LOGISTICS],
    ), new Project(32,
        "HILLWOOD PARK GRODZISK MAZOWIECKI",
        "HILLWOOD POLSKA",
        [ENUM.Filters.LOGISTICS],
    ), new Project(33,
        "INDUSTRIAL PARK POLAND KONIN",
        "HILLWOOD POLSKA",
        [ENUM.Filters.LOGISTICS],
    ), new Project(34,
        "BIOELEKTRA WIERZBICA",
        "BIOELEKTRA WIERZBICA SP. Z O.O.",
        [ENUM.Filters.PRIVATE],
    ), new Project(35,
        "HILLWOOD PARK ZGORZELEC",
        "HILLWOOD POLSKA",
        [ENUM.Filters.LOGISTICS],
    ), new Project(36,
        "HILLWOOD PARK ŁAZY",
        "HILLWOOD POLSKA",
        [ENUM.Filters.LOGISTICS],
    ),
];

export default ProjectsData;