import React from 'react';
import { useTranslation } from 'react-i18next'
import { getItems, icons } from './aboutUs.const'
import Counter from './Counter/Counter';
import './aboutUs.scss';
import Title from '../Title/Title';

const AboutUs = () => {
    const { t } = useTranslation();

    return (
        <section id="AboutUs" className='pt-5'>
            <div className="container">
                <Title>{t("aboutUs.title")}</Title>
                <div>
                    {getItems().map((row, rowIndex) =>
                        <div key={rowIndex} className='row row-items justify-content-lg-between g-2 mb-2' >
                            {row.map((subRow, subRowIndex) => <div key={subRowIndex} className='col-md-12 col-lg-5 item' >
                                <div className='row justify-content-md-between g-2 g-md-4 '>
                                    {subRow.map((cell, cellIndex) =>
                                        <div key={cellIndex} className='col-sm-12 col-md-5 col-lg-6 item ' >
                                            <div><div className='item-image' style={{ backgroundImage: `url("${cell.icon}` }}></div></div>
                                            <p className='item-description'>{t(cell.description)}</p>
                                        </div>
                                    )}
                                </div>
                            </div>)}
                        </div>
                    )}
                </div>
                <div className='row mt-5 justify-content-lg-between'>
                    <div className='col-lg-5 justify-content-center'>
                        <div className='d-flex align-items-start'>
                            <div><div className='item-image' style={{ backgroundImage: `url("${icons.chart}` }}></div></div>
                            <p className='description'>{t("aboutUs.chart")}</p>
                        </div>
                        <div className='d-flex justify-content-center'><div className='map-image' style={{ backgroundImage: `url("${icons.map}` }}></div></div>
                    </div>
                    <div className='col-lg-5 mt-5 mt-lg-0'>
                        <div className='d-flex align-items-start'>
                            <div><div className='item-image' style={{ backgroundImage: `url("${icons.pi}` }}></div></div>
                            <p className='description'>{t("aboutUs.pi")}</p>
                        </div>
                        <Counter />
                    </div>
                </div>
                <div className='d-none mt-3  d-xl-flex w-100'>
                    <div><img src={icons.timeLine} style={{ width: '100%' }} alt="time-line" /></div>
                </div>
            </div>
        </section>
    );
}

export default AboutUs;
