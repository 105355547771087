import React from 'react';
import ProjectCard from "../ProjectCard/ProjectCard";

const ProjectsList = (props) => {
    return <ul className='mt-5 project-list'>
        {props.projects?.map(
            (project, i) =>
                <ProjectCard key={project.id} i={i} project={project} onClick={props.onProjectClick} />
        )}
    </ul>
}

export default ProjectsList;