import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './i18n/config'
import './css/animate.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import './jqueryloader'
import './scss/style.scss'
import { BrowserRouter } from 'react-router-dom'
ReactDOM.render(<BrowserRouter><App /></BrowserRouter>, document.getElementById('root'));


