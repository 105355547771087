import i18next from '../../i18n/config'

export const icons = {
    handshake: require('../../images/about_us/handshake.jpg'),
    bulb: require('../../images/about_us/bulb.jpg'),
    team: require('../../images/about_us/team.jpg'),
    question: require('../../images/about_us/question.jpg'),
    line: require('../../images/about_us/line.jpg'),
    architectWork: require('../../images/about_us/architectwork.jpg'),
    increase: require('../../images/about_us/increase.jpg'),
    improve: require('../../images/about_us/improve.jpg'),
    projects: require('../../images/about_us/projects.jpg'),
    warehouse: require('../../images/about_us/warehouse.jpg'),
    trust: require('../../images/about_us/trust.jpg'),
    poland: require('../../images/about_us/poland.jpg'),
    chart: require('../../images/about_us/chart.jpg'),
    pi: require('../../images/about_us/pi.jpg'),
    map: require('../../images/about_us/map.png'),
    timeLine: require('../../images/about_us/timelineSeparated.png'),
  };

export const getItems = () =>  [
    [
        [
            {
                icon: icons.team,
                description: i18next.t("aboutUs.team")
            },{
                icon:icons.handshake,
                description: i18next.t("aboutUs.handshake")
            }
        ],
        [
            {
                icon:icons.question,
                description: i18next.t("aboutUs.question")
            },{
                icon:icons.line,
                description: i18next.t("aboutUs.line")
            }
        ]
    ],
    [
        [
            {
                icon: icons.bulb,
                description: i18next.t("aboutUs.bulb")
            },{
                icon:icons.architectWork,
                description: i18next.t("aboutUs.architectWork")
            }
        ],
        [
            {
                icon:icons.increase,
                description: i18next.t("aboutUs.increase")
            },{
                icon:icons.improve,
                description: i18next.t("aboutUs.improve")
            }
        ]
    ],
    [
        [
            {
                icon: icons.projects,
                description: i18next.t("aboutUs.projects")
            },{
                icon:icons.warehouse,
                description: i18next.t("aboutUs.warehouse")
            }
        ],
        [
            {
                icon:icons.trust,
                description: i18next.t("aboutUs.trust")
            },{
                icon:icons.poland,
                description: i18next.t("aboutUs.poland")
            }
        ]
    ]
]