import React from 'react';
import { FiltersProvider } from '../Filters/filters.provider';
import { initFilters } from './projects.const';
import Projects from './Projects/Projects';
import ProjectsMap from './ProjectsMap/ProjectsMap';
import './projectsSection.scss'

const ProjectsSection = () => {
  return (
    <FiltersProvider initState={initFilters}>
      <section id="ProjectsSection" className='pt-5 testimony-section'>
        <ProjectsMap />
        <Projects />
      </section>
    </FiltersProvider>
  );
}

export default ProjectsSection;
