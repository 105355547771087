import ENUM from "../../data/Enums";
import ProjectsData from "../../data/ProjectsData";

export const icons = {
  all: require('../../images/projects/all.png'),
  bts: require('../../images/projects/bts.png'),
  individual: require('../../images/projects/individual.png'),
  park: require('../../images/projects/park.png'),
};

export const initFilters = [
  { name: ENUM.Filters.LOGISTICS, status: false, icon: icons.park },
  { name: ENUM.Filters.BTS, status: false, icon: icons.bts },
  { name: ENUM.Filters.PRIVATE, status: false, icon: icons.individual },
];

export const initialState =
{
  filters: initFilters,
  projects: ProjectsData().slice(),
  all: true
};