import React from 'react';


export const Content = () => {
    return (
        <>
            <div className="row pt-5 d-flex">
                <h2 className='col-md-12 ' style={{ fontWeight: 'bold', fontFamily: 'lato', fontSize: '18px' }}>Klauzula informacyjna o przetwarzaniu danych osobowych do celów rekrutacji.</h2>
            </div>
            <div className="row pt-3 d-flex">
                <div className='col-md-12' style={{ fontFamily: 'Lato Regular', fontSize: '16px' }}>
                    Administratorem Pani/Pana danych osobowych jest BLUE AXES Sp. z o.o. z siedzibą w Warszawie, przy ul. Aleja Zjednoczenia 36, KRS:0000960847, NIP: 1182239522, REGON: 521637375.
                    <br />
                    <br />
                    Dane osobowe są przetwarzane w celu realizacji procesu rekrutacji, na podstawie Pani/Pana dobrowolnej zgody. Ma Pani/Pan prawo do wycofania zgody w dowolnym momencie, przy czym cofnięcie zgody nie ma wpływu na zgodność przetwarzania, którego dokonano na jej podstawie przed cofnięciem zgody. Dane osobowe będą przetwarzane aż do ewentualnego wycofania przez Panią/Pana zgody na przetwarzanie danych w procesie rekrutacji, nie dłużej jednak niż do zakończenia rekrutacji, w której bierze Pani/Pan udział. Pani/Pana osobowe nie będą przekazywane do państw trzecich, nie będą profilowane, nie będą podlegać procesom zautomatyzowanego przetwarzania. Podanie danych jest dobrowolne, ale konieczne w celu przeprowadzenia rekrutacji, w której bierze Pani/Pan udział. Ma Pani/Pan prawo dostępu do swoich danych osobowych, ich sprostowania, usunięcia lub ograniczenia przetwarzania, prawo do przenoszenia danych oraz prawo wniesienia skargi do organu nadzorczego.
                    <br />
                    <br />
                    <br />
                    Jeśli wyraża Pan/Pani zgodę na przetwarzanie danych osobowych zawartych w dostarczanych dokumentach aplikacyjnych na potrzeby aktualnych i przyszłych procesów rekrutacyjnych również, proszę o dopisanie zgody o następującej treści: „Wyrażam dobrowolnie zgodę na przetwarzanie moich danych osobowych zawartych w dostarczonych przeze mnie dokumentach aplikacyjnych przez BLUE AXES Sp. z o.o. z siedzibą w Warszawie, przy ul. Aleja Zjednoczenia 36 w celu realizacji aktualnych oraz przyszłych procesów rekrutacyjnych”
                </div>
            </div>
        </>
    )
}