import React from "react";
import { useTranslation } from "react-i18next";

import { useProject } from "./project.hooks";

const Project = ({ projectId, onBack }) => {
    const { t } = useTranslation();
    const { currentProject } = useProject(projectId)

    return <div id="Project" className="d-flex flex-column mt-5 ">
        <button
            className="btn btn-primary d-flex align-items-center gap-2 justify-content-center mb-3 project-back-button"
            onClick={onBack}
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
            </svg>
            <div>{t('projects.backToProjects')}</div>
        </button>
        <div className="d-flex flex-column flex-lg-row">
            <div><img src={currentProject?.detailsPageImages?.[0]} style={{ width: '100%' }} alt={currentProject ? currentProject.title : ""} /></div>
            <div><img src={currentProject?.detailsPageImages?.[1]} style={{ width: '100%' }} alt={currentProject ? currentProject.title : ""} /></div>
        </div>
    </div>
}

export default Project;