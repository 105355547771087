import React from 'react';
import { useTranslation } from 'react-i18next'
import PolandLogo from '../../images/poland.svg'
import USALogo from '../../images/united-states.svg'
import { scrollToTop } from '../../utils/scrollHelpers';
import './header.scss';
import LangImg from './LangImg';
import MenuButton from './MenuButton';
import NavLink from './NavLink';

const logo = require('../../images/logo.png')

const Header = () => {
  const { t, i18n } = useTranslation();

  return (
    <nav className="navbar navbar-expand-lg ftco_navbar ftco-navbar-light" id="ftco-navbar">
      <div className="container-fluid">
        <a href="/"><img className="bottom" src={logo} style={{ height: '55px' }} alt="BlueAxes" /></a>
        <MenuButton />
        <div className="collapse navbar-collapse" id="ftco-nav">
          <ul className="navbar-nav ms-auto">
            <NavLink to={'/'} onClick={scrollToTop}>{'Start'}</NavLink>
            <NavLink to={'/#AboutUs'}>{t('navigation.aboutUs')}</NavLink>
            <NavLink to={'/#Offer'}>{t('navigation.offer')}</NavLink>
            <NavLink to={'/#ProjectsSection'}>{t('navigation.projects')}</NavLink>
            <NavLink to={'/Career'}>{t('navigation.career')}</NavLink>
            <NavLink to={'/#Contact'}>{t('navigation.contact')}</NavLink>
          </ul>
          <LangImg src={PolandLogo} alt="Polski" onClick={() => i18n.changeLanguage('pl')} />
          <LangImg src={USALogo} alt="English" onClick={() => i18n.changeLanguage('en')} />
        </div>
      </div>
    </nav>
  );
}

export default Header;
