import React from 'react';
import { ArcherContainer, ArcherElement } from 'react-archer';
import { useTranslation } from 'react-i18next'
import { getFields } from "./offer.const";

const OfferBottomSection = () => {
    const { t } = useTranslation();
    const fields = getFields();
    return <div className='pt-5 pb-3 d-flex gap-5 gap-lg-3 justify-content-between flex-column flex-lg-row align-items-start align-items-sm-center'>
        <div className='d-flex flex-column justify-content-start fields-column'>
            <ArcherContainer noCurves>
                <ArcherElement relations={fields.fieldsOfSpecialisation.map((element, index) => ({
                    targetId: "element1" + index, targetAnchor: 'top',
                    sourceAnchor: 'bottom',
                    style: { strokeWidth: 1, strokeColor: 'black' }
                }))} id="root1">
                    <p className='text-sm-center mb-3 mb-sm-5'>{t('offer.fieldsOfSpecialisation')}</p>
                </ArcherElement>
                <div className='d-flex justify-content-center flex-column flex-sm-row fields-container ' >
                    {fields.fieldsOfSpecialisation.map((field, index) =>
                        <div className='field' key={index}>
                            <ArcherElement id={"element1" + index}><div><div className='item-image' style={{ backgroundImage: `url("${field.icon}` }}></div></div></ArcherElement>
                            <p className='field-description'>{field.description}</p>
                        </div>)}
                </div>
            </ArcherContainer>
        </div>
        <div className='d-flex flex-column justify-content-start fields-column'>
            <ArcherContainer noCurves>
                <ArcherElement relations={fields.fieldsOfDevelopment.map((element, index) => ({
                    targetId: "element2" + index, targetAnchor: 'top',
                    sourceAnchor: 'bottom',
                    style: { strokeWidth: 1, strokeColor: 'black' }
                }))} id="root2">
                    <p className='text-sm-center mb-3 mb-sm-5'>{t('offer.fieldsOfDevelopment')}</p>
                </ArcherElement>
                <div className='d-flex justify-content-center flex-column flex-sm-row fields-container ' >
                    {fields.fieldsOfDevelopment.map((field, index) =>
                        <div className='field' key={index}>
                            <ArcherElement id={"element2" + index}><div><div className='item-image' style={{ backgroundImage: `url("${field.icon}` }}></div></div></ArcherElement>
                            <p className='field-description'>{field.description}</p>
                        </div>)}
                </div>
            </ArcherContainer>
        </div>
    </div>
}

export default OfferBottomSection;