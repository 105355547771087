import React from 'react';
import Filters from '../../Filters/Filters';
import { useTranslation } from 'react-i18next'
import { useProjects } from './projects.hooks';
import ProjectsList from './ProjectsList/ProjectsList';
import Title from '../../Title/Title';
import Project from './Project/Project';

const Projects = () => {
  const { t } = useTranslation();

  const {
    projects,
    currentProject,
    showProject,
    hideProject,
    currentFilters,
    setAll,
    setFilters,
  } = useProjects();

  let content = <ProjectsList projects={projects} onProjectClick={showProject} />

  if (currentProject) {
    content = <Project projectId={currentProject} onBack={hideProject} />
  }

  return (
    <section id="Projects" className='mb-5 testimony-section'>
      <div className="container">
        <Title>{t("projects.gallery")}</Title>
        <Filters
          onClickAll={setAll}
          all={currentFilters.all}
          onClick={setFilters}
          filters={currentFilters.filters}
          className="mb-5"
          page='gallery'
        />
        {content}
      </div>
    </section>
  );
}

export default Projects;
