import React, { createContext } from "react";
import { useFilters } from "../../hooks/useFilters";

export const FiltersStateContext = createContext({
    currentFilters: null
});

export const FiltersActionsContext = createContext({
    setFilters: null,
    setAll: null
});

export const FiltersProvider = ({ initState, children }) => {
    const { currentFilters, setAll, setFilters } = useFilters(initState)

    const state = { currentFilters }

    const actions = {
        setAll,
        setFilters
    }

    return (
        <FiltersStateContext.Provider value={state}>
            <FiltersActionsContext.Provider value={actions}>
                {children}
            </FiltersActionsContext.Provider>
        </FiltersStateContext.Provider>
    )
}