import React from 'react';
import { useTranslation } from 'react-i18next'
import OfferTopSection from './OfferTopSection';
import OfferBottomSection from './OfferBottomSection';
import './offer.scss';
import Title from '../Title/Title';


const Offer = () => {
  const { t } = useTranslation();

  return (
    <section id="Offer" className='testimony-section pt-5'>
      <div className="container">
        <Title>{t("offer.header")}</Title>
        <OfferTopSection />
        <OfferBottomSection />
      </div>
    </section>
  );
}

export default Offer;
