import React from 'react';
import { useTranslation } from 'react-i18next'

const Filter = ({ onClick, name, status, icon, page }) => {
    const { t } = useTranslation();
    return <div className='d-block d-md-flex justify-content-center d-lg-block'>
        <input
            id={`${t(`filters.${name}`)}${page}`}
            type="checkbox"
            checked={status}
            readOnly
            onClick={() => onClick(name)}
        />
        <label htmlFor={`${t(`filters.${name}`)}${page}`}>
            <div>
                <div className='filter-container'>
                    <div className='d-flex flex-column align-items-center'>
                        {icon && <div><div className='filter-icon' style={{ backgroundImage: `url("${icon}` }}></div></div>}
                        <p className='filter-name'>{t(`filters.image_${name.toLowerCase()}`)}</p>
                    </div>
                    <p className='m-0 filter-description'>{t(`filters.${name}`)}</p>
                </div>

            </div>
        </label>
    </div>
}

export default Filter;