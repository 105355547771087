import React from 'react';
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom';
import { useScrollTop } from '../../hooks/useScrollTop';
import './career.scss';

const Carreer = () => {
    const { t } = useTranslation();
    useScrollTop();
    return (
        <section id="Career" className="hero-wrap">
            <div className="container ">
                <div className='d-flex align-items-center justify-content-center'>
                    <h1>{t('career.header')}</h1>
                </div>

                <div className="row align-items-top pt-5">
                    <p className=''>{t('career.main')}</p>
                </div>

                <div className="row d-flex contact-info align-items-end">
                    <div className="col-md-8">
                        <div className="row align-items-top pt-2">
                            <p className=''>{t('career.extra')}</p>
                        </div>
                    </div>
                    <div className="col-md-4 ">
                        <div className="row">
                            <div className="text-center col-md-12">
                                <p className='col-md-12' style={{ fontWeight: 'bold', marginBottom: '0px' }}>{t('career.applications')}</p>
                                <a className='col-md-12' href="mailto: praca@blueaxes.pl" style={{ marginTop: '-20px' }}> praca@blueaxes.pl</a>
                            </div>
                            <div className="text-center col-md-12">
                                <p className='col-md-12' style={{ fontWeight: 'bold', marginTop: '20px' }}>{t('career.agreements')}</p>
                                <p className='col-md-12' style={{ fontWeight: 'bold', marginTop: '-20px' }}><Link to={`/RecruitmentClause`}>{t('career.clause')}</Link></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    );
}

export default Carreer;
