import React from 'react';
import Header from './Components/Header/Header';
import AboutUs from './Components/AboutUs/AboutUs'
import Startup from './Components/Startup/Startup'
import Offer from './Components/Offer/Offer';
import Team from './Components/Team/Team';
import Contact from './Components/Contact/Contact';
import { Route, Switch } from 'react-router-dom'
import '../node_modules/slick-carousel/slick/slick.css'
import '../node_modules/slick-carousel/slick/slick-theme.css'
import Projects from './Components/Projects/ProjectsSection';
import Career from './Components/Career/Career';
import PrivacyPolicy from './Components/PrivacyPolicy/PrivacyPolicy';
import RecruitmentClause from './Components/RecruitmentClause/RecruitmentClause';

function App() {
  return (
    <div>
      <Header />
      <Switch>
        <Route exact path="/Career" >
          <Career />
        </Route>
        <Route exact path="/RecruitmentClause" >
          <RecruitmentClause />
        </Route>
        <Route exact path="/PrivacyPolicy" >
          <PrivacyPolicy />
        </Route>
        <Route exact path="/" >
          <Startup />
          <AboutUs />
          <Team />
          <Offer />
          <Projects />
          <Contact />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
